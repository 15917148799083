import { PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { IBBlank } from 'src/components/common/IBBlank';
import ScheduleAndPeriodPicker from 'src/components/common/ScheduleAndPeriodPicker';
import Stepper from 'src/components/common/Stepper';
import SolidSVGIcon from 'src/components/icon/SolidSVGIcon';
import SVGIcon from 'src/components/icon/SVGIcon';
import { CAS_LEARNINGOUTCOME } from 'src/constants/ib';
import { useIBCreate } from 'src/container/ib-project';
import { RequestIBCasDtoLearningOutcome, RequestIBCasDtoStrands, RequestIBDto } from 'src/generated/model';
import { meState } from 'src/store';
import { DateFormat, DateUtil } from 'src/util/date';
import { ButtonV2 } from '../../common/ButtonV2';
import { Check } from '../../common/Check';
import { Typography } from '../../common/Typography';
import ColorSVGIcon from '../../icon/ColorSVGIcon';
import { InputField } from '../InputField';

interface IbCASNormalProps {
  modalOpen: boolean;
  setModalClose?: () => void;
  size?: 'medium' | 'large';
  handleBack?: () => void;
  onSuccess: (action: 'CAS_NORMAL', data?: any) => void;
  ablePropragation?: boolean;
}

export function IbCASNormal({
  modalOpen,
  setModalClose,
  handleBack,
  onSuccess,
  ablePropragation = false,
}: PropsWithChildren<IbCASNormalProps>) {
  const me = useRecoilValue(meState);
  const [isFocused, setIsFocused] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [strands, setStrands] = useState<RequestIBCasDtoStrands>({
    creativity: 0,
    activity: 0,
    service: 0,
  });
  const [date, setDate] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
    cycle: string | undefined;
  }>({
    startDate: undefined,
    endDate: undefined,
    cycle: undefined,
  });

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<RequestIBDto>();

  const title = watch('title');

  const areAllFieldsFilled =
    title &&
    title.trim() !== '' &&
    date.startDate &&
    date.endDate &&
    (strands.creativity || strands.activity || strands.service) &&
    selectedIds.length > 0;

  const { createIBProject, isLoading } = useIBCreate({
    onSuccess: (data) => {
      onSuccess('CAS_NORMAL', data);
    },
    onError: (error) => {
      console.error('IB 프로젝트 생성 중 오류 발생:', error);
    },
  });

  const handleGroupChange = (selectedValues: number[]) => {
    setSelectedIds(selectedValues);
  };

  const onSubmit = (data: RequestIBDto) => {
    if (!me?.id) {
      console.error('Leader ID가 없습니다. 로그인 상태를 확인하세요.');
      return;
    }

    if (isLoading) {
      return;
    }

    const learningOutcome: RequestIBCasDtoLearningOutcome = {
      strengthsDevelopment: selectedIds.includes(1),
      newSkills: selectedIds.includes(2),
      initiativePlanning: selectedIds.includes(3),
      perseverance: selectedIds.includes(4),
      teamworkBenefits: selectedIds.includes(5),
      globalIssues: selectedIds.includes(6),
      ethicalChoices: selectedIds.includes(7),
    };

    const requestData: RequestIBDto = {
      title: data.title,
      ibType: 'CAS_NORMAL',
      description: `${me?.name}의 CAS 계획서`,
      activityFrequency: date.cycle,
      startAt: date.startDate ? DateUtil.formatDate(new Date(date.startDate), DateFormat['YYYY-MM-DD']) : undefined,
      endAt: date.endDate ? DateUtil.formatDate(new Date(date.endDate), DateFormat['YYYY-MM-DD']) : undefined,
      leaderId: me.id,
      cas: { ...data.cas, learningOutcome, strands },
    };
    createIBProject(requestData);
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div
      className={`fixed inset-0 z-60 flex h-screen w-full items-center justify-center bg-black bg-opacity-50 ${
        !modalOpen && 'hidden'
      }`}
      onClick={(e) => {
        if (!ablePropragation) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className={`relative w-[848px] overflow-hidden rounded-xl bg-white`}>
        {isLoading && <IBBlank type="section-opacity" />}
        <div className="sticky top-0 z-10 flex h-[88px] items-center justify-between bg-white/70 px-8 pb-6 pt-8 backdrop-blur-[20px]">
          <Typography variant="title1">일반 계획서 작성</Typography>
          <ColorSVGIcon.Close color="gray700" size={32} onClick={setModalClose} className="cursor-pointer" />
        </div>

        <div className="scroll-box flex max-h-[608px] flex-col overflow-auto pb-8 pt-4">
          <div className="flex flex-col gap-6 px-8 pb-8">
            <InputField label="활동 제목" name="title" control={control} placeholder="제목을 입력해주세요" required />
            <div className="flex flex-col gap-3">
              <Typography variant="title3" className="font-semibold">
                활동 일정 및 주기
                <span className="text-primary-red-800">*</span>
              </Typography>
              <div className="relative">
                <div
                  className={`flex h-10 items-center gap-2 rounded-lg border border-primary-gray-200 px-3 py-[9px] focus:outline-none focus:ring-0 ${
                    isFocused && 'border-primary-gray-700'
                  }`}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onClick={() => setCalendarOpen(!calendarOpen)}
                >
                  <SVGIcon.Calendar size={20} color="gray700" />
                  <input
                    className="w-full flex-1 border-none p-0 text-primary-gray-900 placeholder-primary-gray-400 caret-primary-blue-800 focus:border-primary-gray-700 focus:text-primary-gray-700 focus:outline-none focus:ring-0"
                    value={
                      date.startDate && date.endDate
                        ? `${date.startDate.getFullYear()}.${(date.startDate.getMonth() + 1)
                            .toString()
                            .padStart(2, '0')}.${date.startDate
                            .getDate()
                            .toString()
                            .padStart(2, '0')} ~ ${date.endDate.getFullYear()}.${(date.endDate.getMonth() + 1)
                            .toString()
                            .padStart(2, '0')}.${date.endDate.getDate().toString().padStart(2, '0')} ${date.cycle}`
                        : ''
                    }
                    placeholder="활동 일정 및 주기 선택"
                  />
                </div>
                {calendarOpen && (
                  <div className="absolute left-0 top-full z-50 mt-2">
                    <ScheduleAndPeriodPicker
                      initialDate={date}
                      onSave={(finalDate) => {
                        setDate(finalDate);
                        setCalendarOpen(false);
                      }}
                      onCancel={() => setCalendarOpen(false)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center justify-between">
                <Typography variant="title3" className="font-semibold">
                  Strands
                  <span className="text-primary-red-800">*</span>
                </Typography>
                <Typography variant="caption2" className="text-primary-gray-500">
                  단위 : 시간
                </Typography>
              </div>
              <nav className="flex w-full flex-row items-center justify-between gap-3">
                <div className="flex flex-row items-center gap-2 rounded-lg border border-primary-gray-100 p-4">
                  <SolidSVGIcon.C size={20} color="orange800" />
                  <Typography variant="body2" className="w-[82px]">
                    Creativity
                  </Typography>
                  <Stepper
                    number={strands.creativity || 0}
                    setNumber={(value) => setStrands((prev) => ({ ...prev, creativity: value }))}
                  />
                </div>
                <div className="flex flex-row items-center gap-2 rounded-lg border border-primary-gray-100 p-4">
                  <SolidSVGIcon.A size={20} color="orange800" />
                  <Typography variant="body2" className="w-[82px]">
                    Activity
                  </Typography>
                  <Stepper
                    number={strands.activity || 0}
                    setNumber={(value) => setStrands((prev) => ({ ...prev, activity: value }))}
                  />
                </div>
                <div className="flex flex-row items-center gap-2 rounded-lg border border-primary-gray-100 p-4">
                  <SolidSVGIcon.S size={20} color="orange800" />
                  <Typography variant="body2" className="w-[82px]">
                    Service
                  </Typography>
                  <Stepper
                    number={strands.service || 0}
                    setNumber={(value) => setStrands((prev) => ({ ...prev, service: value }))}
                  />
                </div>
              </nav>
            </div>
            <div className="flex flex-col gap-3">
              <Typography variant="title3" className="font-semibold">
                7가지 학습성과
                <span className="text-primary-red-800">*</span>
              </Typography>
              <Check.Group selectedValues={selectedIds} onChange={handleGroupChange} className="grid grid-cols-2 gap-3">
                {CAS_LEARNINGOUTCOME?.map((item) => (
                  <Check.Box
                    key={item.id}
                    label={item.value}
                    size={20}
                    value={item.id}
                    checked={selectedIds.includes(item.id)}
                  />
                ))}
              </Check.Group>
            </div>
          </div>
          <div className="flex flex-col gap-6 border-t border-t-primary-gray-100 px-8 py-8">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <Typography variant="title3" className="font-semibold">
                  활동 설명
                </Typography>
                <Typography variant="caption2" className="text-primary-gray-500">
                  6하원칙에 따라 작성해 주세요.
                </Typography>
              </div>
              <InputField
                label="누가"
                name="cas.sixWhDescription.who"
                type="textarea"
                className="h-40"
                control={control}
                placeholder="내용을 입력해주세요"
              />
              <InputField
                label="언제"
                name="cas.sixWhDescription.when"
                type="textarea"
                className="h-40"
                control={control}
                placeholder="내용을 입력해주세요"
              />
              <InputField
                label="어디서"
                name="cas.sixWhDescription.where"
                type="textarea"
                className="h-40"
                control={control}
                placeholder="내용을 입력해주세요"
              />
              <InputField
                label="무엇을"
                name="cas.sixWhDescription.what"
                type="textarea"
                className="h-40"
                control={control}
                placeholder="내용을 입력해주세요"
              />
              <InputField
                label="어떻게"
                name="cas.sixWhDescription.how"
                type="textarea"
                className="h-40"
                control={control}
                placeholder="내용을 입력해주세요"
              />
              <InputField
                label="왜"
                name="cas.sixWhDescription.why"
                type="textarea"
                className="h-40"
                control={control}
                placeholder="내용을 입력해주세요"
              />
            </div>
          </div>
          <div className="flex flex-col border-t border-t-primary-gray-100 px-8 pt-8">
            <InputField
              label="개인적인 목표"
              name="cas.goal"
              type="textarea"
              className="h-24"
              control={control}
              placeholder="IB 학습자상과 관련하여 작성해 주세요"
            />
          </div>
        </div>

        <div
          className={
            'sticky bottom-0 flex h-[104px] justify-between gap-4 border-t border-t-primary-gray-100 bg-white/70 px-8 pb-8 pt-6 backdrop-blur-[20px]'
          }
        >
          <ButtonV2 variant="solid" color="gray100" size={48} onClick={handleBack}>
            이전
          </ButtonV2>
          <div className="flex gap-3">
            <ButtonV2
              variant="solid"
              color="gray700"
              size={48}
              onClick={handleSubmit(onSubmit)}
              disabled={!areAllFieldsFilled}
            >
              계획서 저장
            </ButtonV2>
          </div>
        </div>
      </div>
    </div>
  );
}
