import { useEffect, useState } from 'react';
import { RadioV2 } from 'src/components/common/RadioV2';
import { Typography } from 'src/components/common/Typography';
import { useIBProposalStatus, useIBProposalSubmissionStatus } from 'src/container/ib-overview';
import {
  IBGetSubmissionStatusCountParams,
  IBGetSubmissionStatusStatus,
  ResponseIBProposalSubmissionStatusDtoDetailStatus,
} from 'src/generated/model';
import ProposalOverviewPanel from './ProposalOverviewPanel';
import { useHistory } from 'react-router-dom';
import PdfConverter from 'src/components/common/PdfConverter';

export default function ProposalView({ grade, klass }: IBGetSubmissionStatusCountParams) {
  const { push } = useHistory();

  const [status, setStatus] = useState<IBGetSubmissionStatusStatus>(
    () => (sessionStorage.getItem('PROJECT_PROPOSAL_STATUS') as IBGetSubmissionStatusStatus) || 'NOT_SUBMITTED',
  );

  const [isClicked, setIsClicked] = useState(false);
  const [downloadGroups, setDownloadGroups] = useState<{ [key: string]: number[] }>({});
  const [currentUrls, setCurrentUrls] = useState<string[]>([]);
  const [currentFileNames, setCurrentFileNames] = useState<string[]>([]);

  const { data } = useIBProposalStatus({
    grade: grade === 0 ? undefined : grade,
    klass: klass === 0 ? undefined : klass,
  });

  const { students = [] } = useIBProposalSubmissionStatus({
    grade: grade === 0 ? undefined : grade,
    klass: klass === 0 ? undefined : klass,
    status,
  });

  const handlePdfDownloadComplete = () => {
    setIsClicked(false);
  };

  const startDownload = () => {
    const downloadUrls: string[] = [];
    const downloadFileNames: string[] = [];
    const downloadGroups: { [key: string]: number[] } = {};
    let currentIndex = 0;

    const submitStudents = students.filter(
      (student) => student.detailStatus === ResponseIBProposalSubmissionStatusDtoDetailStatus.SUBMIT,
    );

    submitStudents.forEach((student) => {
      if (student.proposals?.length > 1) {
        // 여러 개의 제안서가 있는 경우
        const studentIndices: number[] = [];
        student.proposals.forEach((proposal) => {
          downloadUrls.push(`/teacher/ib/ee/${student.id}/proposal/${proposal.id}`);
          downloadFileNames.push(`${student.leader.name}_${proposal.researchTopic}.pdf`);
          studentIndices.push(currentIndex);
          currentIndex++;
        });
        // 학생 이름으로 그룹화
        downloadGroups[student.leader.name] = studentIndices;
      } else if (student.proposals?.length === 1) {
        // 단일 제안서인 경우
        downloadUrls.push(`/teacher/ib/ee/${student.id}/proposal/${student.proposals[0].id}`);
        downloadFileNames.push(`${student.leader.name}_${student.proposals[0].researchTopic}.pdf`);
        currentIndex++;
      }
    });

    setCurrentUrls(downloadUrls);
    setCurrentFileNames(downloadFileNames);
    setDownloadGroups(downloadGroups);
    setIsClicked(true);
  };

  useEffect(() => {
    sessionStorage.setItem('PROJECT_PROPOSAL_STATUS', status);
  }, [status]);

  return (
    <div>
      <Typography variant="title1" className="text-primary-gray-900">
        제안서
      </Typography>
      <RadioV2.Group
        selectedValue={status}
        onChange={(value: IBGetSubmissionStatusStatus) => setStatus(value)}
        className="mb-4 flex flex-row gap-4 py-6"
      >
        <RadioV2.Label
          title="미제출"
          currentNum={data?.notSubmitted || 0}
          TotalNum={data?.total || 0}
          value="NOT_SUBMITTED"
        />
        <RadioV2.Label
          title="승인 전"
          currentNum={data?.submitted || 0}
          TotalNum={data?.total || 0}
          value="SUBMITTED"
        />
        <RadioV2.Label
          title="승인 완료"
          currentNum={data?.approved || 0}
          TotalNum={data?.total || 0}
          value="APPROVED"
        />
      </RadioV2.Group>

      {status === 'NOT_SUBMITTED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2" className="text-primary-gray-900">
            제안서 미제출
          </Typography>
          <ProposalOverviewPanel
            title="미제출"
            buttonText="미제출자 알림 보내기"
            buttonHandler={() => alert('미제출자 알림 보내기')}
            data={students}
          />
        </div>
      )}
      {status === 'SUBMITTED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2" className="text-primary-gray-900">
            제안서 승인 전
          </Typography>
          <ProposalOverviewPanel
            title="제출"
            goDetailPage={(studentIbId) => push(`/teacher/ib/ee/${studentIbId}`)}
            data={students}
            type="SUBMIT"
            buttonText="제안서 전체 다운로드"
            buttonHandler={() => startDownload()}
          />
          <ProposalOverviewPanel
            title="보완 필요"
            goDetailPage={(studentIbId, proposalId) => push(`/teacher/ib/ee/${studentIbId}/proposal/${proposalId}`)}
            buttonHandler={() => alert('알림 보내기')}
            data={students}
            type="REJECT_PLAN"
          />
        </div>
      )}
      {status === 'APPROVED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2" className="text-primary-gray-900">
            제안서 승인 완료
          </Typography>
          <ProposalOverviewPanel
            goDetailPage={(studentIbId, proposalId) => push(`/teacher/ib/ee/${studentIbId}/proposal/${proposalId}`)}
            title="승인 완료"
            data={students}
          />
        </div>
      )}

      {isClicked && (
        <PdfConverter
          url={currentUrls}
          targetElementId="pdf-download"
          saveFileName={currentFileNames}
          saveZipFileName="EE_제안서"
          groupBy={downloadGroups}
          onFinish={handlePdfDownloadComplete}
        />
      )}
    </div>
  );
}
