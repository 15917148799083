export const EE_SUBJECTS = [
  { id: 0, value: '과목 전체' },
  { id: 1, value: '언어와문학' },
  { id: 2, value: '수학 분석과 접근' },
  { id: 3, value: '세계사' },
  { id: 4, value: '생명과학' },
  { id: 5, value: '화학' },
  { id: 6, value: '영어B' },
  { id: 7, value: '영어연극이론과 창작(영어)' },
  { id: 8, value: '비주얼아트(영어)' },
  { id: 9, value: '물리(영어)' },
  { id: 10, value: '컴퓨터과학(영어)' },
];

export const EE_SUBJECTS_CREATE = [
  { id: 1, value: '언어와문학' },
  { id: 2, value: '수학 분석과 접근' },
  { id: 3, value: '세계사' },
  { id: 4, value: '생명과학' },
  { id: 5, value: '화학' },
  { id: 6, value: '영어B' },
  { id: 7, value: '영어연극이론과 창작(영어)' },
  { id: 8, value: '비주얼아트(영어)' },
  { id: 9, value: '물리(영어)' },
  { id: 10, value: '컴퓨터과학(영어)' },
];

export const EE_SUBJECT_CATEGORY_언어와문학 = [
  { id: 1, value: '분류 1. 소논문이 작성된 언어로 쓰여진 하나 이상의 문학 작품에 대한 연구' },
  {
    id: 2,
    value:
      '분류 2. 다른 언어로 쓰여진 하나 이상의 문학작품과 소논문이 작성된 언어로 쓰여진 하나 또는 여러 개의 문학 작품을 비교한 연구 (다른 언어로 쓰여진 작품의 경우 번역된 작품을 공부할 수 있습니다.)',
  },
  {
    id: 3,
    value:
      '분류 3. 소논문의 작성된 언어로 원서가 쓰인 텍스트 하나 혹은 그 이상을 기반으로 하는 언어에 관련된 연구 (다른 언어로 쓰여진 텍스트의 번역본과 비교할 수 있습니다.)',
  },
];

export const EE_SUBJECT_CATEGORY_영어B = [
  {
    id: 1,
    value:
      '분류 1. A specific analysis of the language (its use and structure), normally related to its cultural context or a specific text',
  },
  {
    id: 2,
    value:
      '분류 2-a. An analysis of the impact of a particular socio-cultural issue on the form or use of the language, based on an examination of language use',
  },
  {
    id: 3,
    value:
      '분류 2-b. An analysis of a socio-cultural issue, as illustrated through specific cultural artefact(s) from a country/ community where the language is spoken',
  },
  {
    id: 4,
    value:
      '분류 3. An analysis of a literary type, based on a specific work or works of literature exclusively form the target language.',
  },
];

export const CAS_LEARNINGOUTCOME = [
  { id: 1, value: '자신의 장점&성장 분야 개발' },
  { id: 2, value: '도전과제 수행&새로운 역량 개발' },
  { id: 3, value: 'CAS활동 추진&계획' },
  { id: 4, value: '노력과 인내심' },
  { id: 5, value: '개인의 역량 입증&협력의 이점 인식' },
  { id: 6, value: '세계적 이슈 참여' },
  { id: 7, value: '선택과 행동의 윤리적 측면' },
];
