import { concat } from 'lodash';
import { useState } from 'react';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { RadioV2 } from 'src/components/common/RadioV2';
import { Typography } from 'src/components/common/Typography';
import FrontPaginatedList from 'src/components/FrontPaginatedList ';
import { PopupModal } from 'src/components/PopupModal';
import { useIBInterview } from 'src/container/ib-interview';
import { useIBProfileGetTemplateItemByStudent } from 'src/generated/endpoint';
import { RequestCreateInterviewDtoCategory } from 'src/generated/model';
import { DateFormat, DateUtil } from 'src/util/date';
import { ModalType } from '../FAQList';
import { CoordinatorCAS_Question_AddQuestion } from './CoordinatorCAS_Question_AddQuestion';

export const CAS_QUESTION_TYPES: Record<string, string> = {
  CAS_PROFILE: 'CAS 프로필',
  INTERVIEW: '인터뷰',
};

export const CoordinatorCAS_Question = () => {
  const [activeModal, setActiveModal] = useState<ModalType>(null);
  const [type, setType] = useState<string>('CAS_PROFILE');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { data: interviews, isLoading: interviewLoading, refetch: interviewRefetch } = useIBInterview();

  const { data: profile, isLoading: profileLoading, refetch: profileRefetch } = useIBProfileGetTemplateItemByStudent();
  const [selectedInterviewType, setSelectedInterviewType] = useState<RequestCreateInterviewDtoCategory>();

  const questions = concat(
    [] as any[],
    interviews?.map((el) => ({ ...el, type: 'INTERVIEW' })) || [],
    profile ? { ...profile, type: 'CAS_PROFILE' } : [],
  );

  const refetch = () => {
    interviewRefetch();
    profileRefetch();
  };

  const Header = () => (
    <>
      <div className="w-[68px] text-center">번호</div>
      <div className="w-[92px] text-center">유형</div>
      <div className="w-[632px] text-center">제목</div>
      <div className="w-[188px] text-center">수정일</div>
      <div className="w-[188px] text-center">관리</div>
    </>
  );

  // Item 컴포넌트
  const Item = ({ item, index }: { item: any; index: number }) => (
    <>
      <div className="w-[68px] text-center">{index}</div>
      <div className="flex w-[92px] justify-center">
        <BadgeV2 color="gray" type="solid_regular">
          {CAS_QUESTION_TYPES[item.type]}
        </BadgeV2>
      </div>
      <div className="w-[632px] text-left">{item.type === 'CAS_PROFILE' ? 'CAS 프로필 양식' : item.title}</div>
      <div className="w-[188px] text-center">{DateUtil.formatDate(item.updatedAt, DateFormat['YYYY.MM.DD'])}</div>
      <div className="flex w-[188px] flex-row justify-center gap-2">
        <ButtonV2
          variant="outline"
          size={32}
          color="gray400"
          onClick={(e) => {
            e.stopPropagation();
            handleEditClick(item);
          }}
        >
          수정
        </ButtonV2>
      </div>
    </>
  );

  const handleCreateClick = () => {
    setActiveModal('Category');
  };

  const handleEditClick = (item: any) => {
    setActiveModal('Add');
    setType(item?.type);
    if (item.type === 'INTERVIEW') {
      setSelectedInterviewType(item.category);
    }
  };

  const handleNext = () => {
    setActiveModal('Add');
  };

  const handleBackToProjectSelection = () => {
    setActiveModal(null);
    setSelectedInterviewType(undefined);
  };

  const handleSuccess = () => {
    setActiveModal(null);
    refetch();
    setSelectedInterviewType(undefined);
    setAlertMessage(`${CAS_QUESTION_TYPES[type]}이/가 저장되었습니다.`);
  };

  return (
    <div className="rounded-lg bg-white">
      <div className="m-6 flex flex-row items-center justify-between">
        <Typography variant="title1">양식</Typography>
        <ButtonV2 variant="solid" size={40} color="orange800" onClick={handleCreateClick}>
          추가하기
        </ButtonV2>
      </div>

      {questions?.length ? (
        <FrontPaginatedList
          headerComponent={<Header />}
          itemComponent={(item, index) => <Item item={item} index={index} />}
          page={1}
          pageSize={20}
          totalItems={questions.length}
          items={questions}
          onSelect={(item) => console.log('선택된 항목:', item)}
        />
      ) : (
        <div className="w-full py-6 text-center">생성된 {CAS_QUESTION_TYPES[type]} 내용이 없습니다.</div>
      )}

      {activeModal === 'Category' && (
        <PopupModal
          modalOpen={true}
          setModalClose={() => setActiveModal(null)}
          title={'유형을 선택해주세요'}
          footerButtons={
            <div className="flex gap-2">
              <ButtonV2 variant="solid" color="orange800" size={48} onClick={handleNext}>
                {'다음'}
              </ButtonV2>
            </div>
          }
        >
          <RadioV2.Group selectedValue={type} onChange={(value) => setType(value)} className="flex flex-col gap-3">
            {Object.entries(CAS_QUESTION_TYPES).map(([value, name]) => (
              <RadioV2.Box value={value} title={name} />
            ))}
          </RadioV2.Group>
        </PopupModal>
      )}
      {activeModal === 'Add' && (
        <CoordinatorCAS_Question_AddQuestion
          type={type}
          interviews={interviews}
          profile={profile}
          selectedInterviewType={selectedInterviewType}
          modalOpen={true}
          setModalClose={() => {
            setActiveModal(null);
            setSelectedInterviewType(undefined);
          }}
          handleBack={handleBackToProjectSelection}
          onSuccess={handleSuccess}
        />
      )}
      {alertMessage && (
        <AlertV2
          message={alertMessage}
          confirmText="확인"
          onConfirm={() => setAlertMessage(null)} // closes the AlertV2 by resetting the message
        />
      )}
    </div>
  );
};
