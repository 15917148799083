import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { Pagination } from 'src/components/common/Pagination';
import { useAdminPointGet } from 'src/generated/endpoint';
import { useSearch } from 'src/lib/router';
import { Routes } from 'src/routes';

export function PointPage() {
  const { t } = useTranslation(undefined, { keyPrefix: 'point_page' });
  const { page, size } = useSearch({ page: 1, size: 25 });

  const { data: points } = useAdminPointGet({ page, size });

  return (
    <Admin.Section>
      <Admin.H2>{t('point_section_title')}</Admin.H2>

      <div className="flex gap-2">
        <Button.sm as={Link} children={t('add')} to={Routes.admin.point.new} className="outlined-gray" />
      </div>

      <Admin.Table>
        <Admin.TableHead>
          <Admin.TableRow>
            <Admin.TableHCell children={t('point_title')} />
            <Admin.TableHCell children={t('point_value')} />
          </Admin.TableRow>
        </Admin.TableHead>
        <Admin.TableBody>
          {points?.items.map((point) => (
            <Admin.TableRow key={point.id} to={`${Routes.admin.point.index}/${point.id}`}>
              <Admin.TableCell children={point.title} />
              <Admin.TableCell children={point.value} />
            </Admin.TableRow>
          ))}
        </Admin.TableBody>
      </Admin.Table>

      <Pagination data={points} />
    </Admin.Section>
  );
}
