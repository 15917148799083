import { format } from 'date-fns';
import _, { chain, map } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ReactComponent as FileItemIcon } from 'src/assets/svg/file-item-icon.svg';
import { SuperModal } from 'src/components';
import { DocumentObjectComponentDel } from 'src/components/DocumentObjectComponentDel';
import { ImageObjectComponentDel } from 'src/components/ImageObjectComponentDel';
import { CloseButton, Label, Radio, RadioGroup, Select, Textarea } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { Checkbox } from 'src/components/common/Checkbox';
import { Coachmark2 } from 'src/components/common/CoachMark2';
import ConfirmDialog from 'src/components/common/ConfirmDialog';
import { TextInput } from 'src/components/common/TextInput';
import { Icon } from 'src/components/common/icons';
import { Constants } from 'src/constants';
import { ACTIVITYV3_TYPE_KOR } from 'src/constants/activityv3.enum';
import {
  useAchievementCriteriaGetAll,
  useActivityV3Create,
  useActivityV3Update,
  useGroupsFindAllKlassBySchool,
  useGroupsFindLectureGroupsByTeacher,
  useTeacherGroupsFindBySubject,
} from 'src/generated/endpoint';
import {
  AchievementCriteria,
  ActivityV3,
  Group,
  GroupType,
  RequestCreateActivityV3Dto,
  ResponseSubjectGroupDto,
  SubjectType,
  TeacherGroupsFindBySubjectSubjectType,
  UploadFileTypeEnum,
} from 'src/generated/model';
import { useFileUpload } from 'src/hooks/useFileUpload';
import { useImageAndDocument } from 'src/hooks/useImageAndDocument';
import { toastState } from 'src/store';
import { nameWithId } from 'src/types';
import { getFileNameFromUrl } from 'src/util/file';
import { twMerge } from 'tailwind-merge';

interface ActivityV3AddPageProps {
  activityv3Data?: ActivityV3;
}

export const DEFAULT_EXPLAIN_TEXT = '예) 주제, 주요활동을 중심으로 200자 이내로 활동요약을 작성해주세요.';

export const SUBJECTS_TYPE_ACTIVITY = [
  { id: 1, name: '자율' },
  { id: 2, name: '봉사' },
  { id: 3, name: '진로' },
  { id: 4, name: '동아리' },
];

const subjectExclusionValues = [
  '자율',
  '봉사',
  '동아리',
  '진로',
  '자율활동',
  '봉사활동',
  '동아리활동',
  '진로활동',
  '교내활동',
  '우리반',
];

export const ActivityV3AddPage: React.FC<ActivityV3AddPageProps> = ({ activityv3Data }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [toastMsg, setToastMsg] = useRecoilState(toastState);
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectGroupModalOpen, setSelectGroupModalOpen] = useState(false);
  const [selectCriteriaModalOpen, setSelectCriteriaModalOpen] = useState(false);
  const [modalGroupType, setModalGroupType] = useState<string>('KLASS');
  const [hasStudentText, setHasStudentText] = useState(!!activityv3Data?.hasStudentText || false);
  const [selectedSubjectId, setSelectedSubjectId] = useState(0);
  const [selectedSubsubject, setSelectedSubsubject] = useState('');
  const [selectedCriteriaIds, setSelectedCriteriaIds] = useState<number[]>(
    activityv3Data?.achievementCriteriaIds || [],
  );

  const { push, goBack } = useHistory();
  const [activityv3type, setActivityV3Type] = useState<TeacherGroupsFindBySubjectSubjectType>(SubjectType.LECTURE);
  const [showDialog, setShowDialog] = useState(false);
  const [coachmarkVisible, setCoachmarkVisible] = useState<boolean>(true);

  useEffect(() => {
    const hasSeenCoachmark = localStorage.getItem('activityAddIsFirst');
    if (hasSeenCoachmark) {
      setCoachmarkVisible(false);
    }
  }, []);

  const handleCoachmarkClose = () => {
    setCoachmarkVisible(false);
    localStorage.setItem('activityAddIsFirst', 'not');
  };

  const handleCoachmarOpen = () => {
    setCoachmarkVisible(true);
    localStorage.removeItem('activityAddIsFirst');
    setCurrentStep(1);
  };

  const handleConfirm = () => {
    setShowDialog(false);
    goBack();
  };

  const handleCancel = () => {
    setShowDialog(false);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
  } = useForm<RequestCreateActivityV3Dto>();

  const {
    imageObjectMap,
    documentObjectMap,
    handleImageAdd,
    toggleImageDelete,
    handleDocumentAdd,
    toggleDocumentDelete,
    addFiles,
  } = useImageAndDocument({ images: activityv3Data?.images, documents: activityv3Data?.files });

  const { isUploadLoading, handleUploadFile } = useFileUpload();

  const { data: klassGroups } = useGroupsFindAllKlassBySchool();

  const { data: teacherGroups } = useTeacherGroupsFindBySubject(
    { subjectType: activityv3type },
    { query: { enabled: !!activityv3type } },
  );

  const { data: lectureGroups = [] } = useGroupsFindLectureGroupsByTeacher({
    query: { enabled: activityv3type === SubjectType.LECTURE },
  });

  const { data: achievementChapters } = useAchievementCriteriaGetAll();

  const subjectArray: nameWithId[] = useMemo(() => {
    switch (activityv3type) {
      case SubjectType.ACTIVITY:
        return SUBJECTS_TYPE_ACTIVITY;
      case SubjectType.LECTURE:
        return (
          _.chain(teacherGroups)
            .map((el) => ({ id: el.id, name: el.subject }))
            //@ts-ignore
            .concat(_.map(lectureGroups, (lg: any) => ({ id: lg.id, name: lg.subject })))
            .filter((el) => !subjectExclusionValues.includes(el.name))
            .uniqBy('name')
            .value()
        );
      case SubjectType.ETC:
        return _.chain(teacherGroups)
          .map((el) => ({ id: el.id, name: el.subject }))
          .filter((el) => !subjectExclusionValues.includes(el.name))
          .uniqBy('name')
          .value();
      default:
        return [];
    }
  }, [activityv3type, teacherGroups, lectureGroups]);

  useEffect(() => {
    if (activityv3Data) {
      reset({
        ...activityv3Data,
        groupIds: activityv3Data.groupActivityV3s?.map((el) => el.group?.id) || [],
        startDate: activityv3Data.startDate ? format(new Date(activityv3Data.startDate), 'yyyy-MM-dd') : '',
        endDate: activityv3Data.endDate ? format(new Date(activityv3Data.endDate), 'yyyy-MM-dd') : '',
        studentTextEndDate: activityv3Data.studentTextEndDate
          ? format(new Date(activityv3Data.studentTextEndDate), "yyyy-MM-dd'T'HH:mm")
          : '',
      });
      setActivityV3Type(activityv3Data.type);
      setHasStudentText(activityv3Data.hasStudentText);
      setSelectedGroupIds(activityv3Data.groupActivityV3s?.map((el) => el.group?.id) || []);
      setSelectedCriteriaIds(activityv3Data.achievementCriteriaIds || []);
    }
  }, [activityv3Data]);

  const groupData: Record<string, Group[]> = useMemo(() => {
    switch (activityv3type) {
      case SubjectType.ACTIVITY: {
        const result: any = {};
        teacherGroups
          ?.filter((el) => el.group.type === GroupType.KLUB)
          ?.forEach((el: any) => {
            result[el.subject] = result[el.subject] || [];
            result[el.subject].push(el.group);
          });
        return result;
      }
      case SubjectType.LECTURE: {
        const result: any = {};
        teacherGroups?.forEach((el) => {
          result[el.subject] = result[el.subject] || [];
          result[el.subject].push(el.group);
        });
        lectureGroups?.forEach((el: any) => {
          result[el.subject] = result[el.subject] || [];
          result[el.subject].push(el);
        });
        return result;
      }
      case SubjectType.ETC: {
        const result: any = {};
        teacherGroups?.forEach((el) => {
          result[el.subject] = result[el.subject] || [];
          result[el.subject].push(el.group);
        });
        lectureGroups?.forEach((el: any) => {
          result[el.subject] = result[el.subject] || [];
          result[el.subject].push(el);
        });
        return result;
      }
    }
    return {};
  }, [teacherGroups, lectureGroups]);

  const teacherSubjects =
    groupData[watch('subject')]?.map(
      (el: any) => ({ subject: watch('subject'), group: el }) as ResponseSubjectGroupDto,
    ) || [];

  const { mutateAsync: createActivityV3 } = useActivityV3Create({
    mutation: {
      onSuccess: (data) => {
        setToastMsg('활동이 추가되었습니다.');
        data && push(`/teacher/activityv3/${data.id}/session/add`);
        reset();
      },
      onError: (error) => {
        setToastMsg(error.message);
      },
    },
  });
  const { mutateAsync: updateActivityV3 } = useActivityV3Update({
    mutation: {
      onSuccess: (data) => {
        setToastMsg('활동이 수정되었습니다.');
        data && push(`/teacher/activityv3/${data.id}`);
        reset();
      },
      onError: (error) => {
        setToastMsg(error.message);
      },
    },
  });
  const teacherGroupSubjectInfos = _.chain(teacherSubjects)
    .filter((tg) => tg?.subject === watch('subject'))
    .slice()
    .sort((a, b) => {
      if (!a.group.name || !b.group.name) {
        return 0;
      }
      const aData = a.group.name.match('([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반');
      const bData = b.group.name.match('([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반');

      if (aData?.[1] === bData?.[1]) {
        return Number(aData?.[2]) - Number(bData?.[2]);
      } else {
        return Number(aData?.[1]) - Number(bData?.[1]);
      }
    })
    .value();

  const groups = watch('subject')
    ? activityv3type === SubjectType.ACTIVITY || activityv3type === SubjectType.ETC
      ? _.chain(klassGroups || [])
          .map((el) => ({ group: el, subject: watch('subject') }) as ResponseSubjectGroupDto)
          .concat(teacherSubjects)
          .uniqBy('group.name')
          .value()
      : _.chain(teacherGroupSubjectInfos)
          //@ts-ignore
          .concat(lectureGroups.map((el) => ({ group: el, subject: el.subject }) as ResponseSubjectGroupDto))
          .uniqBy('group.name')
          .value()
    : [];

  const klassGrades = _.uniqBy(groups, 'group.grade').map((el) => el.group.grade);

  const isFormValid = useMemo(() => {
    return activityv3type && watch('subject') && watch('title') && selectedGroupIds.length > 0;
  }, [activityv3type, watch('subject'), watch('title'), selectedGroupIds]);

  const selectedChapter = achievementChapters?.find((el) => el.id === selectedSubjectId);

  const allCriterias = achievementChapters?.reduce(
    (acc: AchievementCriteria[], cur) => acc.concat(cur.achievementCriterias),
    [],
  );

  const subSubjects = chain(selectedChapter?.achievementCriterias || [])
    .map((el) => el.subSubject)
    .uniq()
    .value();

  const steps = [
    {
      title: '1단계 : 활동 생성 기본 설정',
      description: '활동 타입, 과목, 기간, 활동명을 선택하고 학생들에게 보여줄 활동에 대한 설명을 간략하게 요약합니다.',
      actions: [
        { text: '그만보기', onClick: () => handleCoachmarkClose() },
        { text: '다음으로', onClick: () => setCurrentStep((prev) => Math.min(prev + 1, steps.length)) },
      ],
    },
    {
      title: '2단계 : AI 요약',
      description: '공통문구와 성취기준을 입력하면 AI 활동기록부 초안 작성 시 답변 정확도가 높아집니다.',
      actions: [
        { text: '그만보기', onClick: () => handleCoachmarkClose() },
        { text: '이전으로', onClick: () => setCurrentStep((prev) => Math.max(prev - 1, 1)) },
        { text: '다음으로', onClick: () => setCurrentStep((prev) => Math.min(prev + 1, steps.length)) },
      ],
    },
    {
      title: '3단계 : 활동보고서 제출 설정',
      description: '활동보고서는 학생이 자신이 참여한 활동의 주요 내용을 통합 요약한 것입니다.',
      actions: [
        { text: '그만보기', onClick: () => handleCoachmarkClose() },
        { text: '이전으로', onClick: () => setCurrentStep((prev) => Math.max(prev - 1, 1)) },
        { text: '마치기', onClick: () => handleCoachmarkClose() },
      ],
    },
  ];

  return (
    <>
      <div className="col-span-6">
        {/* 배경 */}
        <div className="flex h-screen-6 flex-col bg-gray-50 p-2 md:h-screen md:px-10 md:pb-20 md:pt-10 3xl:px-[208px] 3xl:pb-[128px] 3xl:pt-[64px]">
          {/* 활동 생성 박스 */}
          <div className="relative h-full">
            <div className="h-full overflow-y-auto bg-white p-2 md:py-5 3xl:py-20">
              {/* 활동 생성하기 박스 */}
              <div className="flex w-full flex-col gap-2 bg-white pb-8 md:px-10 3xl:px-30">
                <div className="flex items-center gap-2">
                  <div className="text-3xl font-bold">활동 {activityv3Data ? '수정' : '생성'}하기</div>
                  <div
                    className="text-md flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-gray-500"
                    onClick={() => handleCoachmarOpen()}
                  >
                    ?
                  </div>
                </div>
                <div className="text-18 font-normal text-[#444]">
                  학생들에게 활동&nbsp;/&nbsp; 활동보고서의 상세 내용을 안내할 수 있습니다.
                </div>
                {/* <Button.lg className="absolute right-15 top-12 flex items-center rounded-full border border-[#777] px-1 text-[#777]">
              <div className="whitespace-pre border-r border-[#777] px-2 py-1">임시저장</div>
              <div className="px-2 py-1">4</div>
            </Button.lg> */}
              </div>

              <div className="min-w-2/3 h-full md:px-10 3xl:px-30">
                <div className="flex w-full gap-x-0.5 border-b border-[#333333] py-2">
                  <div className="text-xl font-bold">활동</div>
                  {currentStep === 1 && coachmarkVisible && (
                    <span className="relative flex h-1.5 w-1.5">
                      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-brandblue-1 opacity-75"></span>
                      <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-brandblue-1"></span>
                      <Coachmark2 steps={steps} currentStep={currentStep} position="bottom" arrowDirection="top" />
                    </span>
                  )}
                </div>
                {/* 테이블 영역 */}

                <div className="flex w-full flex-col">
                  <div className="border-gray-[#444] flex h-14 items-center border-b">
                    <div className="text-[#333333 w-30 whitespace-pre py-3 font-bold">
                      <div className="flex gap-x-0.5">
                        <p>타입</p>
                        <div className="h-1.5 w-1.5 overflow-hidden rounded-full bg-orange-500" />
                      </div>
                    </div>
                    <div className="py-3">
                      <RadioGroup className="flex items-center space-x-4" onChange={() => {}}>
                        {Object.entries(ACTIVITYV3_TYPE_KOR).map(([type, kor]) => (
                          <div className={`flex items-center space-x-2 ${!!activityv3Data && 'opacity-60'}`} key={type}>
                            <Radio
                              key={type}
                              id={type}
                              name="type"
                              value={type}
                              checked={activityv3type === type}
                              onChange={() => {
                                setActivityV3Type(type as SubjectType);
                                setSelectedGroupIds([]);
                              }}
                              disabled={!!activityv3Data}
                            ></Radio>
                            <Label
                              htmlFor={type}
                              children={kor}
                              className={'cursor-pointer ' + (!!activityv3Data && 'text-gray-600')}
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>

                  <div className="border-gray-[#444] flex h-14 items-center border-b py-3">
                    <div className="w-30 whitespace-pre font-bold text-[#333333]">
                      <div className="flex gap-x-0.5">
                        <p>과목</p>
                        <div className="h-1.5 w-1.5 overflow-hidden rounded-full bg-orange-500" />
                      </div>
                    </div>
                    <div className="py-3">
                      {subjectArray.length >= 1 ? (
                        <Select.lg
                          id="subject"
                          {...register('subject', { required: true, onChange: () => setSelectedGroupIds([]) })}
                          disabled={!!activityv3Data}
                          className="h-10 rounded-lg border border-[#CCCCCC]"
                        >
                          <option selected hidden value="">
                            과목을 선택하세요.
                          </option>
                          {subjectArray?.map((subject) => <option value={subject.name || ''}>{subject.name}</option>)}
                        </Select.lg>
                      ) : (
                        <Select.lg disabled className="h-10 rounded-lg border border-[#CCCCCC]">
                          <option>과목이 존재하지 없습니다.</option>
                        </Select.lg>
                      )}

                      <Label.Error children={errors.subject?.message} />
                    </div>
                  </div>

                  <div className="border-gray-[#444] flex h-14 items-center border-b">
                    <div className="w-30 whitespace-pre font-bold text-[#333333]">활동 기간</div>
                    <div className="py-3">
                      <div className="flex items-center space-x-2 overflow-hidden">
                        <TextInput
                          id="startDate"
                          type="date"
                          className="h-10 w-40 rounded-lg border border-[#CCCCCC]"
                          {...register('startDate')}
                        />
                        <Label.Error children={errors.startDate?.message} />
                        ~
                        <TextInput
                          id="endDate"
                          type="date"
                          className="h-10 w-40 rounded-lg border border-[#CCCCCC]"
                          {...register('endDate')}
                        />
                        <Label.Error children={errors.endDate?.message} />
                      </div>
                    </div>
                  </div>

                  <div className="border-gray-[#444] flex h-14 w-full flex-1 items-center border-b">
                    <div className="w-30 flex-shrink-0 whitespace-pre py-3 font-bold text-[#333333]">
                      <div className="flex gap-x-0.5">
                        <p>활동명</p>
                        <div className="h-1.5 w-1.5 overflow-hidden rounded-full bg-orange-500" />
                      </div>
                    </div>
                    <div className="flex-grow py-3">
                      <TextInput
                        id="title"
                        placeholder="예) 통계포스터 만들기"
                        className="h-10 rounded-lg border border-[#CCCCCC]"
                        {...register('title', { required: true })}
                      />
                      <Label.Error children={errors.title?.message} />
                    </div>
                  </div>

                  <div className="border-gray-[#444] flex w-full flex-1 items-center border-b">
                    <div className="w-30 flex-shrink-0 whitespace-pre py-3 font-bold text-[#333333]">활동 설명</div>
                    <div className="flex-grow py-3">
                      <Textarea
                        id="description"
                        className="h-20 resize-none rounded-lg border border-[#CCCCCC]"
                        rows={3}
                        placeholder="활동에 대한 설명을 입력해 주세요."
                        {...register('description')}
                      />
                      <Label.Error children={errors.description?.message} />
                    </div>
                  </div>
                  <div className="border-gray-[#444] flex h-14 w-full flex-1 items-center border-b">
                    <div className="w-30 flex-shrink-0 whitespace-pre py-3 font-bold text-[#333333]">
                      <div className="flex gap-x-0.5">
                        <p>공통문구</p>
                        {currentStep === 2 && coachmarkVisible && (
                          <span className="relative flex h-1.5 w-1.5">
                            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-brandblue-1 opacity-75" />
                            <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-brandblue-1" />
                            <Coachmark2 steps={steps} currentStep={currentStep} />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex-grow py-3">
                      <Textarea
                        id="commonText"
                        className="h-20 resize-none rounded-lg border border-[#CCCCCC]"
                        rows={3}
                        placeholder="공통문구를 입력해 주세요. 공통문구는 선생님에게만 보여집니다."
                        {...register('commonText')}
                      />
                      <Label.Error children={errors.commonText?.message} />
                    </div>
                  </div>
                  {activityv3type === SubjectType.LECTURE && (
                    <div className="border-gray-[#444] flex h-14 w-full flex-1 items-center border-b">
                      <div className="w-30 flex-shrink-0 whitespace-pre py-3 font-bold text-[#333333]">
                        <div className="flex gap-x-0.5">
                          <p>성취기준</p>
                        </div>
                      </div>
                      <div className="flex w-full flex-grow flex-col gap-2 py-3">
                        <div
                          className="flex h-10 w-28 cursor-pointer items-center justify-center rounded-lg border border-dashed border-[#CCCCCC] bg-gray-50 px-6 py-2"
                          onClick={() => {
                            setSelectCriteriaModalOpen(true);
                          }}
                        >
                          <Icon.Plus />
                        </div>
                        {!!selectedCriteriaIds?.length && (
                          <div className="flex flex-col gap-2">
                            {selectedCriteriaIds.map((criteriaId) => (
                              <div
                                className="flex w-max max-w-full items-center rounded-lg border border-[#333333] px-2 py-1"
                                key={criteriaId}
                              >
                                <div className="flex h-8 flex-grow items-center overflow-x-hidden">
                                  <div className="w-full truncate text-neutral-500">
                                    {allCriterias?.find((el) => el.id === criteriaId)?.criteria}
                                  </div>
                                </div>
                                <div className="flex min-w-max items-center justify-center bg-white px-2">
                                  <div className="flex h-full w-full cursor-pointer items-center justify-center text-white">
                                    <Icon.Close
                                      className="cursor-pointer rounded-full bg-zinc-100 p-1 text-zinc-400"
                                      onClick={() =>
                                        setSelectedCriteriaIds(selectedCriteriaIds.filter((id) => id !== criteriaId))
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="border-gray-[#444] flex h-14 flex-1 items-center border-b">
                    <div className="w-30 flex-shrink-0 whitespace-pre py-3 font-bold text-[#333333]">
                      <div className="flex gap-x-0.5">
                        <p>전달대상</p>
                        <div className="h-1.5 w-1.5 overflow-hidden rounded-full bg-orange-500" />
                      </div>
                    </div>
                    <div className="flex-grow py-3">
                      <div className="flex items-center space-x-2 placeholder:w-full">
                        {!!selectedGroupIds?.length ? (
                          <div className="flex w-full flex-wrap gap-2 space-x-2">
                            {selectedGroupIds.map((groupId) => (
                              <div
                                className="flex h-10 items-center justify-between gap-2 space-x-2 whitespace-pre rounded-lg border border-orange-500 px-2 py-1"
                                key={groupId}
                              >
                                {groups?.find((el) => el.group?.id === groupId)?.group?.name}
                                <Icon.Close
                                  className="cursor-pointer rounded-full bg-zinc-100 p-1 text-zinc-400"
                                  onClick={() => setSelectedGroupIds(selectedGroupIds.filter((id) => id !== groupId))}
                                />
                              </div>
                            ))}
                            <div
                              className="flex h-10 w-28 cursor-pointer items-center justify-center rounded-lg border border-dashed border-[#CCCCCC] bg-gray-50"
                              onClick={() => {
                                if (!watch('subject')) {
                                  setToastMsg('먼저 과목을 선택해주세요.');
                                  return;
                                }
                                setSelectGroupModalOpen(true);
                              }}
                            >
                              <Icon.Plus />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="flex h-10 w-28 cursor-pointer items-center justify-center rounded-lg border border-dashed border-[#CCCCCC] bg-gray-50 px-6 py-2"
                            onClick={() => {
                              if (!watch('subject')) {
                                setToastMsg('먼저 과목을 선택해주세요.');
                                return;
                              }
                              setSelectGroupModalOpen(true);
                            }}
                          >
                            <Icon.Plus />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="border-gray-[#444] flex h-14 flex-1 items-center border-b">
                    <div className="w-30 flex-shrink-0 whitespace-pre font-bold text-[#333333]">첨부파일</div>
                    <div className="py-3">
                      {/* 이미지 */}
                      {[...imageObjectMap].length > 0 && (
                        <div className="grid w-full flex-grow grid-flow-row grid-cols-6 gap-2 pb-2">
                          {[...imageObjectMap].map(([key, value]) => (
                            <ImageObjectComponentDel
                              key={key}
                              id={key}
                              imageObjet={value}
                              onDeleteClick={toggleImageDelete}
                            />
                          ))}
                        </div>
                      )}
                      {/* 문서 */}
                      {[...documentObjectMap].length > 0 && (
                        <div className="flex flex-col gap-1 pb-2">
                          {[...documentObjectMap].map(([key, value]) => (
                            <DocumentObjectComponentDel
                              key={key}
                              id={key}
                              documentObjet={value}
                              onDeleteClick={toggleDocumentDelete}
                            />
                          ))}
                        </div>
                      )}
                      <input
                        type="file"
                        id="file-upload"
                        name="file-upload"
                        className="hidden"
                        multiple
                        onChange={(e) => {
                          e.preventDefault();
                          const files = e.target.files;
                          if (!files || files.length === 0) return;
                          addFiles(files);
                        }}
                      />
                      <div className="flex items-center gap-4">
                        <label
                          className={`flex h-10 w-28 cursor-pointer items-center justify-center rounded-lg border border-dashed border-[#CCCCCC] bg-gray-50`}
                          htmlFor="file-upload"
                        >
                          <Icon.Plus />
                        </label>
                        <p className="text-xs text-neutral-500">100MB 이하의 이미지/문서 파일을 첨부할 수 있습니다.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="pt-8">
                    <div className="flex items-center space-x-8 border-b border-[#333333] pb-2">
                      <div className="flex gap-x-0.5">
                        <p className="text-xl font-bold">활동보고서</p>
                        <span className="relative flex h-1.5 w-1.5">
                          {currentStep === 3 && coachmarkVisible ? (
                            <>
                              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-brandblue-1 opacity-75" />
                              <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-brandblue-1" />
                              <Coachmark2 steps={steps} currentStep={currentStep} />
                            </>
                          ) : (
                            <span className="relative inline-flex h-full w-full rounded-full bg-transparent" />
                          )}
                        </span>
                      </div>
                      <div className="flex items-center space-x-2 text-14">
                        <Checkbox
                          id="hasStudentText"
                          checked={!hasStudentText}
                          onChange={() => setHasStudentText(!hasStudentText)}
                        />
                        <label htmlFor="hasStudentText">활동보고서 제출 없음</label>
                      </div>
                    </div>
                  </div>
                  <table className="w-full table-fixed">
                    <tr className="border-gray-[#444] border-b">
                      <td className="h-14 w-44 whitespace-pre">활동보고서 제출 마감일</td>
                      <td>
                        <div className="flex items-center space-x-2">
                          <TextInput
                            id="studentTextEndDate"
                            type="datetime-local"
                            className="h-10 w-60 rounded-lg border border-[#CCCCCC]"
                            {...register('studentTextEndDate')}
                            disabled={!hasStudentText}
                          />
                          <div>까지</div>
                        </div>
                        <Label.Error children={errors.studentTextEndDate?.message} />
                      </td>
                    </tr>
                    <tr className="border-gray-[#444] border-b">
                      <td className="h-14 whitespace-pre py-3">활동보고서 예시</td>
                      <td className="py-3">
                        <Textarea
                          id="exampleText"
                          className="h-14 resize-none rounded-lg border border-[#CCCCCC]"
                          cols={3}
                          placeholder="활동보고서 작성 예시를 작성해 주세요."
                          {...register('exampleText')}
                          disabled={!hasStudentText}
                        />
                        <Label.Error children={errors.exampleText?.message} />
                      </td>
                    </tr>
                    <tr className="border-gray-[#444] border-b">
                      <td className="h-14 whitespace-pre py-3">활동보고서 작성 가이드</td>
                      <td className="py-3">
                        <Textarea
                          id="explainText"
                          className="h-14 resize-none rounded-lg border border-[#CCCCCC]"
                          placeholder={DEFAULT_EXPLAIN_TEXT}
                          {...register('explainText')}
                          disabled={!hasStudentText}
                        />
                        <Label.Error children={errors.explainText?.message} />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            {/* 하단 버튼 영역 */}
            <div className="absolute -bottom-14 left-0 w-full 3xl:-bottom-20">
              <div className="flex items-center justify-between">
                <div>
                  <Button
                    className="h-12 w-40 rounded-lg border border-neutral-500 bg-white text-lg font-semibold"
                    onClick={() => setShowDialog(true)}
                  >
                    취소
                  </Button>
                </div>

                <div className="flex items-center space-x-2">
                  <Button
                    className="h-12 w-40 rounded-lg border border-orange-500 bg-white text-lg font-semibold text-orange-500 disabled:border-gray-500 disabled:text-gray-500"
                    onClick={() => setPreviewOpen(true)}
                    disabled={!isFormValid}
                  >
                    미리보기
                  </Button>
                  <Button
                    className="h-12 w-40 rounded-lg bg-orange-500 text-lg font-semibold text-white disabled:bg-gray-500"
                    disabled={!isFormValid}
                    onClick={handleSubmit(async (data) => {
                      // file image 처리
                      const imageFiles = [...imageObjectMap.values()]
                        .filter((value) => !value.isDelete && value.image instanceof File)
                        .map((value) => value.image) as File[];
                      const imageFileNames = await handleUploadFile(
                        UploadFileTypeEnum['activityv3/images'],
                        imageFiles,
                      );
                      // url image 처리
                      const imageUrlNames = [...imageObjectMap.values()]
                        .filter((value) => !value.isDelete && typeof value.image === 'string')
                        .map((value) => value.image) as string[];
                      const allImageNames = [...imageUrlNames, ...imageFileNames];
                      // file document 처리
                      const documentFiles = [...documentObjectMap.values()]
                        .filter((value) => !value.isDelete && value.document instanceof File)
                        .map((value) => value.document) as File[];
                      const documentFileNames = await handleUploadFile(
                        UploadFileTypeEnum['activityv3/files'],
                        documentFiles,
                      );
                      const documentUrlNames = [...documentObjectMap.values()]
                        .filter((value) => !value.isDelete && typeof value.document === 'string')
                        .map((value) => value.document) as string[];
                      const allDocumentNames = [...documentUrlNames, ...documentFileNames];
                      const _data = {
                        ...data,
                        ...(!data.explainText && { explainText: DEFAULT_EXPLAIN_TEXT }),
                        groupIds: selectedGroupIds,
                        images: allImageNames,
                        files: allDocumentNames,
                        type: activityv3type as SubjectType,
                        hasStudentText,
                        achievementCriteriaIds: selectedCriteriaIds,
                      };
                      if (activityv3Data) {
                        updateActivityV3({ id: activityv3Data.id, data: _data });
                      } else {
                        createActivityV3({ data: _data });
                      }
                    })}
                  >
                    확인
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <SuperModal
            width="max-w-7xl w-[960px] 3xl:h-2/3 h-3/4"
            modalOpen={selectCriteriaModalOpen}
            setModalClose={() => setSelectCriteriaModalOpen(false)}
          >
            <div className="flex h-full w-full flex-col p-10">
              <h1 className="text-xl font-bold">성취기준 선택</h1>

              {/* 성취기준 박스 */}
              <div className="flex h-full w-full gap-2 overflow-hidden pt-2">
                <div className="flex w-full flex-row rounded border border-[#CCCCCC]">
                  {/* 과목 선택 박스 */}
                  <div className="w-[300px] overflow-y-auto border-r border-[#CCCCCC] p-1">
                    {map(achievementChapters, (ac) => (
                      <div
                        className={twMerge(
                          'flex cursor-pointer items-center justify-between space-x-4 rounded p-2 text-14',
                          selectedSubjectId === ac.id && 'bg-orange-500 font-bold text-white',
                        )}
                        onClick={() => setSelectedSubjectId(ac.id)}
                      >
                        <div>{ac.subject}</div>
                        <Icon.ChevronRight stroke={selectedSubjectId === ac.id ? '#FFF' : '#333D4B'} />
                      </div>
                    ))}
                  </div>
                  {/* 하위 과목 선택 박스 */}
                  <div className="w-[300px] overflow-y-auto border-r border-[#CCCCCC] p-1">
                    {subSubjects?.map((subsubject) => (
                      <div
                        key={subsubject}
                        className={twMerge(
                          'flex cursor-pointer items-center justify-between space-x-4 rounded p-2 text-14',
                          selectedSubsubject === subsubject && 'bg-orange-500 font-bold text-white',
                        )}
                        onClick={() => setSelectedSubsubject(subsubject)}
                      >
                        <div>{subsubject}</div>
                        <Icon.ChevronRight stroke={selectedSubsubject === subsubject ? '#FFF' : '#333D4B'} />
                      </div>
                    ))}
                  </div>

                  {/* 소속 그룹 선택 박스 */}
                  <div className="flex w-full flex-col space-y-2 overflow-y-auto p-4">
                    {selectedChapter?.achievementCriterias
                      ?.filter((el) => el.subSubject === selectedSubsubject)
                      ?.map((criteria) => (
                        <label
                          key={criteria.id}
                          htmlFor={String(criteria.id)}
                          className={twMerge(
                            'col-span-2 flex w-full items-start justify-start space-x-2 rounded border border-[#DDDDDD] p-2 text-sm',
                            selectedCriteriaIds.includes(criteria.id) && 'border-brand-1 bg-light_orange',
                          )}
                          onClick={() =>
                            selectedCriteriaIds.includes(criteria.id)
                              ? setSelectedCriteriaIds(selectedCriteriaIds.filter((el) => el !== criteria.id))
                              : setSelectedCriteriaIds(selectedCriteriaIds.concat(criteria.id))
                          }
                        >
                          <Checkbox
                            id={String(criteria.id)}
                            checked={selectedCriteriaIds.includes(criteria.id)}
                            onChange={(e) => {
                              e.stopPropagation();
                              selectedCriteriaIds.includes(criteria.id)
                                ? setSelectedCriteriaIds(selectedCriteriaIds.filter((el) => el !== criteria.id))
                                : setSelectedCriteriaIds(selectedCriteriaIds.concat(criteria.id));
                            }}
                          />
                          <div className="whitespace-pre-line font-semibold">{criteria.criteria}</div>
                        </label>
                      ))}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end space-x-2 pt-4">
                <Button
                  className="h-12 w-28 rounded-lg border border-neutral-500 bg-white text-lg font-semibold"
                  onClick={() => {
                    setSelectCriteriaModalOpen(false);
                    setSelectedCriteriaIds([]);
                  }}
                >
                  취소
                </Button>
                <Button
                  className="h-12 w-28 rounded-lg bg-orange-500 text-lg font-semibold text-white disabled:bg-gray-500"
                  disabled={!selectedCriteriaIds.length}
                  onClick={() => setSelectCriteriaModalOpen(false)}
                >
                  확인
                </Button>
              </div>
            </div>
          </SuperModal>

          <SuperModal
            width="max-w-7xl w-[960px] 3xl:h-2/3 h-3/4"
            modalOpen={selectGroupModalOpen}
            setModalClose={() => setSelectGroupModalOpen(false)}
          >
            <div className="flex h-full w-full flex-col space-y-4 p-10">
              <h1 className="text-xl font-bold">전달대상 선택</h1>

              {/* 전달대상 박스 */}
              <div className="flex h-full w-full gap-2 overflow-hidden">
                {/* 그룹 선택 박스 + 소속 그룹 선택 박스  */}
                <div className="flex w-full flex-row rounded border border-[#CCCCCC]">
                  {/* 그룹 선택 박스 */}
                  <div className="w-[300px] border-r border-[#CCCCCC] p-1">
                    <div
                      className={twMerge(
                        'flex cursor-pointer items-center justify-between space-x-4 rounded p-2',
                        modalGroupType === 'KLASS' && 'bg-orange-500 font-bold text-white',
                      )}
                      onClick={() => setModalGroupType('KLASS')}
                    >
                      <div>학급소속 그룹</div>
                      <Icon.ChevronRight stroke={modalGroupType === 'KLASS' ? '#FFF' : '#333D4B'} />
                    </div>
                    <div
                      className={twMerge(
                        'flex cursor-pointer items-center justify-between space-x-4 rounded p-2',
                        modalGroupType === 'LECTURE' && 'bg-orange-500 font-bold text-white',
                      )}
                      onClick={() => setModalGroupType('LECTURE')}
                    >
                      <div>강의 시간표 그룹</div>
                      <Icon.ChevronRight stroke={modalGroupType === 'LECTURE' ? '#FFF' : '#333D4B'} />
                    </div>
                    <div
                      className={twMerge(
                        'flex cursor-pointer items-center justify-between space-x-4 rounded p-2',
                        modalGroupType === 'KLUB' && 'bg-orange-500 font-bold text-white',
                      )}
                      onClick={() => setModalGroupType('KLUB')}
                    >
                      <div>사용자 정의 그룹</div>
                      <Icon.ChevronRight />
                    </div>
                  </div>

                  {/* 소속 그룹 선택 박스 */}
                  <div className="w-full overflow-y-auto p-4">
                    <div className="mb-2 font-bold">
                      {modalGroupType === 'KLASS' && '학급소속 그룹'}
                      {modalGroupType === 'LECTURE' && '강의 시간표 그룹'}
                      {modalGroupType === 'KLUB' && '사용자 정의 그룹'}
                    </div>
                    {modalGroupType === 'KLASS' && (
                      <div className="flex w-full items-start space-x-2 overflow-y-auto">
                        {klassGrades.map((grade) => {
                          const groupData = groups.filter(
                            (el) => el.group.type === 'KLASS' && el.group.grade === grade,
                          );
                          if (!groupData.length) return <></>;

                          return (
                            <div className="w-full gap-2 space-y-2" key={grade}>
                              {groupData.length > 1 && (
                                <label
                                  htmlFor={`check-all-${grade}`}
                                  className="col-span-2 flex w-full items-center justify-start space-x-2 rounded border border-[#DDDDDD] p-2 text-sm"
                                  onClick={() =>
                                    groupData.filter((g) => selectedGroupIds.includes(g.group.id)).length ===
                                    groupData.length
                                      ? setSelectedGroupIds(
                                          _.chain(groups)
                                            .filter((el) => selectedGroupIds.includes(el.group.id))
                                            .filter((el) => el.group.grade !== grade)
                                            .map((el) => el.group.id)
                                            .value(),
                                        )
                                      : setSelectedGroupIds(
                                          _.chain(selectedGroupIds)
                                            .concat(groupData.map((el) => el.group.id))
                                            .uniq()
                                            .value(),
                                        )
                                  }
                                >
                                  <Checkbox
                                    id={`check-all-${grade}`}
                                    checked={
                                      groupData.filter((g) => selectedGroupIds.includes(g.group.id)).length ===
                                      groupData.length
                                    }
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      groupData.filter((g) => selectedGroupIds.includes(g.group.id)).length ===
                                      groupData.length
                                        ? setSelectedGroupIds(
                                            _.chain(groups)
                                              .filter((el) => selectedGroupIds.includes(el.group.id))
                                              .filter((el) => el.group.grade !== grade)
                                              .map((el) => el.group.id)
                                              .value(),
                                          )
                                        : setSelectedGroupIds(
                                            _.chain(selectedGroupIds)
                                              .concat(groupData.map((el) => el.group.id))
                                              .uniq()
                                              .value(),
                                          );
                                    }}
                                  />
                                  <div className="whitespace-pre font-semibold">{grade}학년 전체</div>
                                </label>
                              )}
                              {groupData?.map((el) => (
                                <label
                                  htmlFor={'check-' + el.group?.id}
                                  className="flex items-center justify-start space-x-2 rounded border border-[#DDDDDD] p-2 text-sm"
                                  key={el.group?.id}
                                  onClick={() =>
                                    selectedGroupIds.includes(el.group?.id)
                                      ? setSelectedGroupIds(selectedGroupIds.filter((id) => id !== el.group?.id))
                                      : setSelectedGroupIds(selectedGroupIds.concat(el.group?.id))
                                  }
                                >
                                  <Checkbox
                                    id={'check-' + el.group?.id}
                                    checked={selectedGroupIds.includes(el.group?.id)}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      selectedGroupIds.includes(el.group?.id)
                                        ? setSelectedGroupIds(selectedGroupIds.filter((id) => id !== el.group?.id))
                                        : setSelectedGroupIds(selectedGroupIds.concat(el.group?.id));
                                    }}
                                  />
                                  <div>{el.group?.name}</div>
                                </label>
                              ))}
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {modalGroupType === 'LECTURE' && activityv3type !== SubjectType.ACTIVITY && (
                      <div className="grid w-full grid-cols-2 gap-2 overflow-y-auto">
                        {groups?.map(({ group: el }) => (
                          <label
                            htmlFor={'check-' + el.id}
                            className="flex items-center justify-start space-x-2 rounded border border-[#DDDDDD] p-2 text-sm"
                            key={el.id}
                            onClick={() =>
                              selectedGroupIds.includes(el.id)
                                ? setSelectedGroupIds(selectedGroupIds.filter((id) => id !== el.id))
                                : setSelectedGroupIds(selectedGroupIds.concat(el.id))
                            }
                          >
                            <Checkbox
                              id={'check-' + el.id}
                              checked={selectedGroupIds.includes(el.id)}
                              onChange={(e) => {
                                e.stopPropagation();
                                selectedGroupIds.includes(el.id)
                                  ? setSelectedGroupIds(selectedGroupIds.filter((id) => id !== el.id))
                                  : setSelectedGroupIds(selectedGroupIds.concat(el.id));
                              }}
                            />
                            <div>{el.name}</div>
                          </label>
                        ))}
                      </div>
                    )}
                    {modalGroupType === 'KLUB' && (
                      <div className="grid w-full grid-cols-2 gap-2 overflow-y-auto">
                        {groups
                          ?.filter((g) => g.group.type === GroupType.KLUB)
                          ?.map((el) => (
                            <label
                              htmlFor={'check-' + el.group?.id}
                              className="flex items-center justify-start space-x-2 rounded border border-[#DDDDDD] p-2 text-sm"
                              key={el.group?.id}
                              onClick={() =>
                                selectedGroupIds.includes(el.group?.id)
                                  ? setSelectedGroupIds(selectedGroupIds.filter((id) => id !== el.group?.id))
                                  : setSelectedGroupIds(selectedGroupIds.concat(el.group?.id))
                              }
                            >
                              <Checkbox
                                id={'check-' + el.group?.id}
                                checked={selectedGroupIds.includes(el.group?.id)}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  selectedGroupIds.includes(el.group?.id)
                                    ? setSelectedGroupIds(selectedGroupIds.filter((id) => id !== el.group?.id))
                                    : setSelectedGroupIds(selectedGroupIds.concat(el.group?.id));
                                }}
                              />
                              <div>{el.group?.name}</div>
                            </label>
                          ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* 내가 선택한 그룹 박스 */}
                <div className="flex w-full min-w-[210px] max-w-[20%] flex-col rounded border border-[#777777] p-4">
                  <div className="mb-2 font-bold">선택한 그룹</div>
                  <div className="flex h-full w-full flex-col space-y-2 overflow-y-auto">
                    {selectedGroupIds
                      .slice()
                      .sort((a, b) => a - b)
                      .map((groupId) => (
                        <div
                          className="flex w-full items-center justify-between space-x-2 rounded border border-stone-300 p-2 text-14"
                          key={groupId}
                        >
                          {groups?.find((el) => el.group?.id === groupId)?.group?.name}
                          <Icon.Close
                            className="cursor-pointer rounded-full bg-zinc-100 p-1 text-zinc-400"
                            onClick={() => setSelectedGroupIds(selectedGroupIds.filter((id) => id !== groupId))}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end space-x-2">
                <Button
                  className="h-12 w-28 rounded-lg border border-neutral-500 bg-white text-lg font-semibold"
                  onClick={() => {
                    setSelectGroupModalOpen(false);
                    setSelectedGroupIds([]);
                  }}
                >
                  취소
                </Button>
                <Button
                  className="h-12 w-28 rounded-lg bg-orange-500 text-lg font-semibold text-white disabled:bg-gray-500"
                  disabled={!selectedGroupIds.length}
                  onClick={() => setSelectGroupModalOpen(false)}
                >
                  확인
                </Button>
              </div>
            </div>
          </SuperModal>

          {showDialog && (
            <ConfirmDialog
              message="저장되지 않은 내용이 있습니다."
              description={`저장되지 않은 내용은 다시 불러올 수 없습니다. \n한번 더 확인해 주세요.`}
              confirmText="나가기"
              cancelText="취소"
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          )}

          {/* 미리보기 모달 영역 */}
          <SuperModal
            width="w-1/4 h-2/3 overflow-y-auto"
            modalOpen={previewOpen}
            setModalClose={() => setPreviewOpen(false)}
          >
            <div className="flex max-h-screen-12 flex-col lg:border-r lg:border-gray-300">
              <div className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-300 bg-gray-50 px-4 py-2">
                <div className="flex items-center gap-2">
                  <span className="text-lg font-bold">미리보기</span>
                  <span className="ml-2 text-14 text-gray-600">학생에게 보여지는 화면입니다.</span>
                </div>
                <CloseButton onClick={() => setPreviewOpen(false)} />
              </div>
              <div className="h-full bg-gray-50 pb-2">
                <div className="flex flex-col space-y-2 p-4 text-left">
                  <div className="scroll-box flex w-full items-center space-x-2 overflow-x-scroll">
                    {groups
                      ?.filter((g) => selectedGroupIds.includes(g.group?.id))
                      ?.map((el) => (
                        <div
                          key={el.group?.id}
                          className="whitespace-pre rounded-md bg-brand-5 px-2 py-1 text-sm font-bold text-gray-800"
                        >
                          {el.group?.name}
                        </div>
                      ))}
                  </div>
                  <div className="max-w-min whitespace-pre rounded-md bg-gray-300 px-2 py-1 text-sm font-bold text-gray-800">
                    {activityv3type && ACTIVITYV3_TYPE_KOR[activityv3type as SubjectType]}
                  </div>
                  <div className={`text-18 font-bold`}>{watch('title')}</div>
                  <div className="text-13 text-gray-500">
                    기간: {watch('startDate') && format(new Date(watch('startDate') || ''), 'yyyy.MM.dd')} ~{' '}
                    {watch('endDate') && format(new Date(watch('endDate') || ''), 'yyyy.MM.dd')}
                  </div>
                  {[...imageObjectMap].length > 0 && (
                    <div className="w-full pb-2">
                      {[...imageObjectMap].map(([key, value]) => (
                        <ImageObjectComponentDel key={key} id={key} imageObjet={value} />
                      ))}
                    </div>
                  )}
                  {/* 문서 */}
                  {[...documentObjectMap].length > 0 && (
                    <div className="flex flex-col gap-1 pb-2">
                      {[...documentObjectMap].map(([key, value]) => (
                        <div key={key} className="flex h-8 items-center space-x-2 rounded bg-stone-50 px-3 py-1">
                          <FileItemIcon />
                          {typeof value.document === 'string' ? (
                            <a
                              className="ml-2 text-xs text-neutral-500"
                              href={`${Constants.imageUrl}${value.document}`}
                              target="_blank"
                              rel="noreferrer"
                              download={getFileNameFromUrl(value.document)}
                            >
                              {getFileNameFromUrl(value.document)}
                            </a>
                          ) : (
                            <div className="w-full overflow-x-hidden whitespace-pre text-xs text-neutral-500">
                              {value?.document.name}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {watch('description') && (
                  <div className="mb-2 px-4">
                    <div className="text-16 font-semibold">활동 설명</div>
                    <div className="w-full whitespace-pre-line break-words text-15">{watch('description')}</div>
                  </div>
                )}
                {hasStudentText && watch('exampleText') && (
                  <div className="px-4">
                    <div className="text-16 font-semibold">학생 활동 보고서 예시</div>
                    <div className="mt-1 whitespace-pre-line border border-gray-300 p-2 text-15">
                      {watch('exampleText')}
                    </div>
                  </div>
                )}
                {hasStudentText && (
                  <div className="bg-gray-50 p-4">
                    <div className="w-full cursor-pointer border-b border-gray-300 pb-2 text-16 font-semibold">
                      학생 활동 보고서
                    </div>
                    <div className="text-gray-600">
                      활동의 <b>차시</b> 참여 후, 생기부 작성을 위해 예시를 참고하여 <b>학생 활동 보고서</b>를
                      작성해주세요.
                    </div>
                    <Textarea className="my-2 resize-none" placeholder={watch('explainText') || DEFAULT_EXPLAIN_TEXT} />
                    <Button.lg
                      className="w-full bg-brand-1 text-white disabled:bg-gray-500"
                      disabled
                      children="제출하기"
                    />
                    <div className="mt-4 w-full text-center text-13 text-red-500">
                      *미리보기 화면에선 제출이 불가합니다.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </SuperModal>
        </div>
      </div>
    </>
  );
};
