import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Label } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { TextInput } from 'src/components/common/TextInput';
import { adminPointCreate, adminPointUpdate, useAdminPointGetOne } from 'src/generated/endpoint';
import { PointCreateBody, PointUpdateBody } from 'src/generated/model';
import { form } from 'src/lib/form';
import { Routes } from 'src/routes';
import { toastState } from 'src/store';
import { getErrorMsg } from 'src/util/status';

type PointSaveBody = PointCreateBody | PointUpdateBody;

function isCreating(id: number, body: PointSaveBody): body is PointCreateBody {
  return Number.isNaN(id);
}

export function PointEditPage() {
  const { t } = useTranslation(undefined, { keyPrefix: 'point_edit_page' });
  const { goBack } = useHistory();
  const { id: idString } = useParams<{ id?: string }>();
  const id = Number(idString);
  const setToastMsg = useSetRecoilState(toastState);

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<PointSaveBody>({ defaultValues: { title: '', value: 1 } });

  const { data: point } = useAdminPointGetOne(id);

  useEffect(() => point && reset(point), [point]);

  async function save(params: PointSaveBody) {
    try {
      isCreating(id, params) ? await adminPointCreate(params) : await adminPointUpdate(id, params);
    } catch (error) {
      setToastMsg(getErrorMsg(error));
    }
    setToastMsg(`저장되었습니다: ${params.title}`);
    goBack();
  }

  return (
    <Admin.Section className="max-w-xl">
      <Admin.H2>{t(id ? 'edit_point' : 'add_point')}</Admin.H2>

      <Label.col>
        <Label.Text children={'*' + t('point_title')} />
        <TextInput {...register('title', form.length(1, 100))} />
        <Label.Error children={errors.title?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children={'*' + t('point_value')} />
        <TextInput type="number" {...register('value', form.minmax(-100, 100))} />
        <Label.Error children={errors.value?.message} />
      </Label.col>

      <div className="mt-4 grid grid-cols-2 gap-4">
        <Link
          children={t('cancel')}
          to={id ? `../${id}` : Routes.admin.point.index}
          className="button-lg outlined-gray"
        />
        <Button.lg children={t('save')} disabled={!isValid} onClick={handleSubmit(save)} className="filled-gray" />
      </div>
    </Admin.Section>
  );
}
