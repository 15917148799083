import FAQList from './FAQList';

export default function CoordinatorIB() {
  return (
    <main className="w-full">
      <section className="flex flex-col">
        <FAQList />
      </section>
    </main>
  );
}
