import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ResponseExhibitionDto } from 'src/generated/model';
import { api } from 'src/lib/axios';

// 이미지를 로드하고 변환하는 함수
const loadImage = async (imageUrl: string): Promise<HTMLImageElement> => {
  // URL이 data:image로 시작하면 그대로 사용
  if (imageUrl.startsWith('data:image')) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = imageUrl;
    });
  }

  // 서명된 URL 가져오기
  const response = await api.get<string>(`/api/images/presigned-url?url=${imageUrl}`);
  const signedUrl = response.data;

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = signedUrl;
  });
};

export const downloadExhibitionPdf = async (
  data: ResponseExhibitionDto | undefined,
  student: { name: string; klass: string },
  returnBlob = false,
) => {
  if (!data) {
    return;
  }

  const totalWordCount = data?.wordCount1 + data?.wordCount2 + data?.wordCount3;

  // A4 크기 설정 (mm 단위)
  const a4Width = 210;

  // PDF 초기화
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
    compress: true,
  });

  // 표지
  const coverDiv = document.createElement('div');
  coverDiv.className = 'pdf-page';
  coverDiv.style.width = `${a4Width}mm`;
  coverDiv.innerHTML = `
      <div class="flex flex-col justify-center items-center gap-4 p-10 pt-20">
       <h1 class="text-2xl font-bold text-center w-4/5 break-keep text-wrap">${data?.themeQuestion}</h1>
       <span>단어 : ${totalWordCount}</span>
      </div>
    `;

  document.body.appendChild(coverDiv);

  try {
    // 표지를 이미지로 변환
    const coverCanvas = await html2canvas(coverDiv, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: false,
      width: a4Width * 3.78, // 픽셀로 변환 (1mm = 3.78px)
    });

    // 표지 이미지를 PDF에 추가
    const coverImgData = coverCanvas.toDataURL('image/jpeg', 1.0);
    const coverImgProps = doc.getImageProperties(coverImgData);
    const pdfWidth = doc.internal.pageSize.getWidth();
    const coverHeight = (coverImgProps.height * pdfWidth) / coverImgProps.width;

    // 표지를 페이지 상단에 추가
    doc.addImage(coverImgData, 'JPEG', 0, 0, pdfWidth, coverHeight);
    let currentY = coverHeight; // 표지 다음부터 시작할 Y 위치

    // 임시 표지 div 요소 제거
    document.body.removeChild(coverDiv);

    // 각 대상에 대한 HTML 요소를 생성하고 PDF로 변환
    for (let targetNum = 1; targetNum <= 3; targetNum++) {
      const targetContent = data?.[`targetContent${targetNum}` as keyof ResponseExhibitionDto];
      const targetImage = data?.[`targetImage${targetNum}` as keyof ResponseExhibitionDto];

      // 이미지 미리 로드
      const img = targetImage ? await loadImage(targetImage as string) : null;

      // 새로운 div 요소 생성
      const targetDiv = document.createElement('div');
      targetDiv.className = 'pdf-page';
      targetDiv.style.width = `${a4Width}mm`;
      targetDiv.innerHTML = `
      <div class="flex flex-col gap-4 pt-10 pb-1 px-10">
        <div class="mb-4">
          <img
            src="${img ? img.src : ''}"
            alt="대상 ${targetNum} 이미지"
            class="h-[200px] w-[267px] rounded-lg object-cover"
          />
        </div>
        <div class="whitespace-pre-wrap">${targetContent}</div>
      </div>
    `;

      document.body.appendChild(targetDiv);

      const canvas = await html2canvas(targetDiv, {
        scale: 2,
        useCORS: true,
        allowTaint: false,
        logging: false,
        width: a4Width * 3.78,
      });

      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const imgProps = doc.getImageProperties(imgData);
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const pageHeight = doc.internal.pageSize.getHeight();

      // 현재 페이지에 내용을 추가할 수 있는지 확인
      if (currentY + pdfHeight > pageHeight) {
        // 현재 페이지에 남은 공간이 부족하면 새 페이지 추가
        doc.addPage();
        currentY = 0;
      }

      // 현재 위치에 이미지 추가
      doc.addImage(imgData, 'JPEG', 0, currentY, pdfWidth, pdfHeight);

      // 다음 내용의 Y 위치 업데이트
      currentY += pdfHeight;

      // 임시 div 요소 제거
      document.body.removeChild(targetDiv);
    }

    // Reference 섹션 추가
    const referenceDiv = document.createElement('div');
    referenceDiv.className = 'pdf-page';
    referenceDiv.style.width = `${a4Width}mm`;
    referenceDiv.innerHTML = `
    <div class="flex flex-col gap-4 pt-10 pb-1 px-10">
      <h2 class="text-2xl font-bold">Reference</h2>
      <div class="whitespace-pre-wrap">${data?.reference}</div>
    </div>
  `;

    document.body.appendChild(referenceDiv);

    const referenceCanvas = await html2canvas(referenceDiv, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: false,
      width: a4Width * 3.78,
    });

    const referenceImgData = referenceCanvas.toDataURL('image/jpeg', 1.0);
    const referenceImgProps = doc.getImageProperties(referenceImgData);
    const referenceHeight = (referenceImgProps.height * pdfWidth) / referenceImgProps.width;
    const pageHeight = doc.internal.pageSize.getHeight();

    // 현재 페이지에 내용을 추가할 수 있는지 확인
    if (currentY + referenceHeight > pageHeight) {
      doc.addPage();
      currentY = 0;
    }

    // Reference 이미지 추가
    doc.addImage(referenceImgData, 'JPEG', 0, currentY, pdfWidth, referenceHeight);

    // 임시 div 요소 제거
    document.body.removeChild(referenceDiv);

    // PDF 파일 저장 또는 Blob 반환
    if (returnBlob) {
      return doc.output('blob');
    } else {
      doc.save(`TOK_전시회_${student.klass}_${student.name}_${data?.themeQuestion}.pdf`);
    }
  } catch (error) {
    console.error(error);
  } finally {
    // 혹시 남아있을 수 있는 임시 요소들 정리
    if (document.body.contains(coverDiv)) {
      document.body.removeChild(coverDiv);
    }
  }
};
